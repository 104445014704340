import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const TitleSearches = () => (
  <Layout>
    <SEO title="Title Searches" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Title Searches</h1>
        <h2>Services include:</h2>
        <div className="row my-4">
          <div className="col-md-6 col-lg-4">
            <ul>
              <li>Chain of Title</li>
              <li>Curative Title</li>
              <li>Management of all Title Documents</li>
              <li>Project Management of Title Efforts</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4">
            <ul>
              <li>Surface Title</li>
              <li>Title File Review/Accuracy and Data Entry</li>
              <li>Title Support for Eminent Domain</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4">
            <img src={hawkeye} className="img-fluid" />
          </div>
        </div>

        <p>
          Gray Hawk Land Solutions manages your title needs in the most
          efficient & industry leading manner available today. By taking
          advantage of our state-of-the-art project management database, Irth Solutions, and leveraging online resources we are able to provide
          incredible value and save our clients a substantial amount of their
          capital budget by eliminating the need in most cases for per diem and
          mileage allowances to remote field agents.{" "}
        </p>
        <p>
          Occasionally when online resources are not available, Gray Hawk
          implements a strategy to hire local title agents which provides local
          knowledge & familiarity to projects. By doing so, we continue to be
          able to offer capital budget savings to our clients by eliminating the
          need for per diem and mileage allowances. All final title packages are
          reviewed in our corporate office in Greenwood Village, Colorado by the
          head of our corporate title and her staff by utilizing the Irth Solutions platform. This centralized strategy allows us to provide
          accurate and high-quality title work on all our project deliverables
          by following a consistent quality control program that is implemented
          from the top down in our organization.{" "}
        </p>
        <p>
          By being early adopters of artificial intelligence technology in our
          industry, Gray Hawk Land Solutions has learned from experience how to
          leverage technology, proprietary modeling & algorithms, online
          resources, specialized software & data scientists to get a jump start
          on all our client’s projects. We learned a long time ago that
          projects' In-Service dates rarely change but the rest of the timelines
          always seemed to be shortened or altered to accommodate them. Being
          able to take advantage of our unique skill sets allows us to get
          started quicker, get more done and get ahead of project milestone
          dates and timelines from the outset. By not losing valuable time at
          the beginning, we are able to meet our client’s timelines and dates at
          the end.{" "}
        </p>
        <p>
          Our Title Agents are the best in the industry, we strive to offer
          knowledgeable, experienced, and professional title agents to all of
          our clients. What makes our methodology different is that we implement
          our proprietary artificial intelligence, machine learning and natural
          language processing capabilities to the process that no other
          right-of-way firm is capable of. Why pay for people to extract data,
          enter data, classify data, or extract and plot legal descriptions when
          our A.I. can do it at a fraction of the cost? Our title agents are
          left to do what they are experts at, which is verifying the actual
          chain of title. We are confident that we can execute any title project
          at a significantly less cost, all while increasing the accuracy of the
          work by eliminating human error.{" "}
        </p>
        <p>
          We are changing the way the industry operates: it has been too
          antiquated for too long. We are also changing the way clients are
          billed for title services. We are able to offer pricing based off of
          actual data extraction points per document so that you’re not paying
          for services that you don’t need. Remember, a human has to read entire
          documents to extract the data that’s relevant. Our A.I. and machine
          learning process reads those same documents and extracts the same data
          only thousands of times quicker and more accurately. If a human title
          agent takes 1 hour to extract the data, our process takes seconds and
          moves on to the next. The savings of doing title our way is obvious.
          Please let us show you a demonstration of our capabilities.
        </p>
      </div>
    </div>
  </Layout>
)

export default TitleSearches
